@import "minima";

body {
  font: 400 16px/1.5 -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

// Keyframes calculation: https://www.devtwins.com/blog/css-cross-fading-images
// n (image count) = 3
// a (presentation time) = 3
// b (crossfade time) = 0.5
// t (total) = (3 + 0.5) * 3 = 10.5

// step 1: 0
// step 2: a/t = 29%
// step 3: (a + b) / t = 33%
// step 4: 100 - (b/t) = 95%

@keyframes fade {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  35% {
    opacity: 0;
  }
  85% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.hero {
  .appstore-link {
    text-align: center;
    margin-top: 32px;
    margin-bottom: 40px;
  }

  h1, .subtitle, .hero-section p {
    text-align: center;
  }

  h1 {
    font-size: 72px;
    font-weight: 600;  
  }

  h2 {
    text-align: center;
    margin-top: 2em;
    font-size: 2em;
  }

  .subtitle {
    font-size: 24px;
  }

  .hero-section {
    padding-top: 2em;
    padding-bottom: 2em;
  }

  .hero-section.a {
    background-color: #eee;
  }

  .animation-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  .animation-container img {
    grid-area: 1/1/1/1;
    animation: fade 10.5s infinite;
  //   // position: absolute;
  //   // top: 0;
  //   // left: 0;
  //   // width: 100%;
  //   // height: 100%;
  }

  .animation-container .step1 {
    animation-delay: 0s;
  }

  .animation-container .step2 {
    animation-delay: 3.5s;
  }

  .animation-container .step3 {
    animation-delay: 7s;
  }

}

.pk-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.pk-footer-button {
  flex-grow: 1;
  min-width: 135px;
  margin-right: 32px;
}

.pk-footer-description {
  flex-grow: 2;
}

@media screen and (min-width: 800px) {

  .hero {
    .hero-section img {
      max-width: 320px;
    }

    .before-after {
      display: grid;
      grid-template-columns: 50% 50%;
      column-gap: 32px;
    }

    .subtitle {
      font-size: 40px;
    }

    .hero-section {
      display: grid;
    }

    .hero-section.a {
      grid-template-columns: 40% 60%;
    }

    .hero-section.b {
      grid-template-columns: 60% 40%;
    }


    .hero-section.a p {
      text-align: center;
    }

    .hero-section.b p {
      text-align: center;
    }

    .hero-section.a .animation-container {
      margin-left: auto;
      max-width: 320px;
    }

    .hero-section.a > div, .hero-section.b > div {
      max-width: 800px;
    }

    .hero-section.b > div {
      margin-left: auto;
    }

    .hero-section .subtitle {
      line-height: 48px;
      font-weight: 600;
      margin-top: 2em;
      margin-left: 1em;
      margin-right: 1em;
    }

    .copy {
      font-size: 24px;
      font-weight: 200;
      margin-left: 1em;
      margin-right: 1em;
    }
  } 

  .pk-footer {
    flex-wrap: nowrap;
  }

  .hero .before-after {
    justify-content: space-between;
  }
}
